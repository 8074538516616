<template>
  <tr>
    <td>
      <p class="mb-0 mt-1">{{ item.user.username }}</p>
      <p class="ma-0">{{ item.user.phone }}</p>
      <p class="mt-0 mb-1">{{ item.user.email }}</p>
    </td>
    <td>
      {{ item.user.organization.name }}
    </td>
    <td :class="typesChanges[item.action].color + '--text' + ' font-weight-bold'">
      {{ typesChanges[item.action].title }}
    </td>
    <td>{{ timestampToDatetime(item.created_at) }}</td>
    <td>
      <BaseBtn
        @click="$emit('click', item)"
        title="Посмотреть изменения"
        color="info"
        x-small
      />
    </td>
  </tr>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import formatters from '@/components/mixins/formatters'

export default {
  name: 'ListItem',
  components: { BaseBtn },
  mixins: [formatters],
  props: {
    item: Object
  },
  computed: {
    typesChanges () {
      this.isLoading = true
      const list = this.$store.getters['serviceData/get']('log.action')
      const types = {}
      list.map((item) => {
        types[item.value] = {
          title: item.label,
          color: (item.value === 1 || item.value === 4) ? 'green' : (item.value === 3 || item.value === 5) ? 'red' : 'orange'
        }
      })
      this.isLoading = false
      return types
    }
  }

}
</script>
