export default {
  data () {
    return {
      filter: {},
      items: {},
      currentOpenItem: {},
      isDialogChanges: false,
      localQuery: {
        options: {
          page: 1,
          itemsPerPage: 25
        }
      },
      isLoading: false
    }
  },
  computed: {
    typesChanges () {
      this.$set(this, 'isLoading', true)
      const list = this.$store.getters['serviceData/get']('log.action')
      const types = {}
      list.map((item) => {
        types[item.value] = {
          title: item.label,
          color: (item.value === 1 || item.value === 4) ? 'green' : (item.value === 3 || item.value === 5) ? 'red' : 'orange'
        }
      })
      this.$set(this, 'isLoading', false)
      return types
    }
  },
  methods: {
    handleChangesBtn (item) {
      this.isDialogChanges = true
      this.currentOpenItem = item
    },
    async getChanges () {
      this.$set(this, 'isLoading', true)
      const response = await this.$store.dispatch('server/get', {
        url: '/log?model_id=' + this.modelId,
        params: this.localQuery
      })
      this.$set(this, 'items', response)
      this.$set(this, 'isLoading', false)
      return true
    }
  },
  watch: {
    isDialogChanges: {
      handler () {
        if (!this.isDialogChanges) {
          this.$set(this, 'currentOpenItem', {})
        }
      },
      immediate: true
    },
    'localQuery.options': {
      handler () {
        this.getChanges()
      },
      deep: true,
      immediate: true
    }
  }
}