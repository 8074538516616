<template>
  <div>
    <slot name="filter"></slot>
    <v-data-table
      v-model="selectedItems"
      dense
      :headers="localHeaders"
      :items="items.models"
      :item-key="itemKey"
      :multi-sort="multiSort"
      :options.sync="syncOptions"
      class="pa-5 elevation-1 rounded-0 ma-3 scroll-header-tables"
      :loading="loading"
      :server-items-length="fallbackItemsLength"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 500, 1000]
      }"
      :options="{
        itemsPerPage: itemsPerPage
      }"
    >
      <!-- Добавляем чекбокс "Выбрать все" -->
      <template
        v-if="allItems && allItems.length"
        v-slot:header.checkbox
      >
        <v-checkbox
          :value="selectAll"
          @change="toggleSelectAll"
        ></v-checkbox>
      </template>
      <!--   [header.slot] формируется в headersWithTooltip  -->
      <template
        v-for="header in headersWithTooltip"
        v-slot:[header.slot]
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ header.text }}</span>
          </template>
          <span>{{ header.tooltipText }}</span>
        </v-tooltip>
      </template>

      <template #item="{item, isSelected}">
        <slot
          :is-selected="isSelected"
          name="item"
          :item="item"
        ></slot>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import { cleanClone } from '@/components/utils/common'

export default {
  name: 'BaseTable',
  props: {
    value: Array,
    headers: Array,
    items: Object,
    loading: Boolean,
    options: Object,
    showSelect: { type: Boolean, default: false },
    itemKey: String,
    multiSort: {
      type: Boolean,
      default: false
    },
    allItems: Array // нужен для того, чтобы можно было выбрать все обходя пагинацию
  },
  data () {
    return {
      selectAll: false,
      itemsPerPage: 100
    }
  },
  watch: {
    selectAll (newVal) {
      this.toggleSelectAllItems(newVal)
    }
  },
  computed: {
    //note: если не задавать общее количество элементов заранее, страница автоматически сбросится до 1
    fallbackItemsLength () {
      let length = 0
      if (this.items.count) {
        length = this.items.count
      } else if (this.$route.query.pagination) {
        length = this.$route.query.pagination.pageNumber * this.$route.query.pagination.perPage
      }

      return length
    },
    syncOptions: {
      get () {
        return this.options
      },
      set (val) {
        this.$emit('update:options', val)
      }
    },
    selectedItems: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    localHeaders () {
      let headers = cleanClone(this.headers)
      if (this.showSelect && this.headers[0].value !== 'checkbox') {
        headers.unshift({
          text: '',
          sortable: false,
          align: 'start',
          value: 'checkbox', // Добавляем значение 'checkbox' для чекбокса "Выбрать все"
          slot: 'header.checkbox' // Задаем слот для чекбокса "Выбрать все"
        })
      }
      return headers
    },
    headersWithTooltip () { // находим хедерсы которые будут отображаться с подсказкой и формируем в них же название слота для их же отоброжения
      let headers = cleanClone(this.headers)
      headers.forEach(header => {
        if (header.showTooltip) header.slot = 'header.' + header.value
      })
      return headers
    }
  },
  methods: {
    toggleSelectAll () {
      this.selectAll = !this.selectAll
    },
    toggleSelectAllItems (value) {
      if (value) {
        this.selectedItems = this.allItems
      } else {
        this.selectedItems = []
      }
    }
  }
}
</script>

<style scoped>
  /deep/ .v-data-table__wrapper { /*по дефолту. В некоторых местах надо перезаписывать.*/
    max-height: calc(100vh - 163px);
  }
</style>
