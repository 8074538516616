var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("filter"),_c('v-data-table',{staticClass:"pa-5 elevation-1 rounded-0 ma-3 scroll-header-tables",attrs:{"dense":"","headers":_vm.localHeaders,"items":_vm.items.models,"item-key":_vm.itemKey,"multi-sort":_vm.multiSort,"options":_vm.syncOptions,"loading":_vm.loading,"server-items-length":_vm.fallbackItemsLength,"footer-props":{
      'items-per-page-options': [25, 50, 100, 500, 1000]
    },"options":{
      itemsPerPage: _vm.itemsPerPage
    }},on:{"update:options":function($event){_vm.syncOptions=$event}},scopedSlots:_vm._u([(_vm.allItems && _vm.allItems.length)?{key:"header.checkbox",fn:function(){return [_c('v-checkbox',{attrs:{"value":_vm.selectAll},on:{"change":_vm.toggleSelectAll}})]},proxy:true}:null,_vm._l((_vm.headersWithTooltip),function(header){return {key:header.slot,fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltipText))])])]},proxy:true}}),{key:"item",fn:function(ref){
    var item = ref.item;
    var isSelected = ref.isSelected;
return [_vm._t("item",null,{"isSelected":isSelected,"item":item})]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }