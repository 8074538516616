<template>
  <div style="overflow-y: auto; max-height: 50vh">
    <div class="wrapper-info-user" v-if="currentOpenItem.user">
      <p class="mb-2 pl-0">
        <v-icon
          color="#64C7FF"
          class="mr-3"
          size="28"
        >mdi-account-circle-outline
        </v-icon>
        {{ currentOpenItem.user.username }}
      </p>
      <v-divider color="#64C7FF"></v-divider>
      <p class="ma-0 mt-2">
        <v-icon
          color="#64C7FF"
          class="mr-1"
          size="18"
        >mdi-phone
        </v-icon>
        {{ currentOpenItem.user.phone }}
      </p>
      <p
        v-if="currentOpenItem.user"
        class="ma-0"
      >
        <v-icon
          color="#64C7FF"
          class="mr-1"
          size="18"
        >mdi-email
        </v-icon>
        {{ currentOpenItem.user.email }}
      </p>
    </div>

    <v-card-title class="pl-0 pb-0 mt-2">Список атрибутов:</v-card-title>
    <div
      class="mt-1"
      v-for="(item, index) in currentOpenItem.logItems"
      :key="item.id"
    >
      <v-list-item-title class="pl-0 pb-2 pt-1">Атрибут: <span class="blue--text">{{ item.attr_name }}</span>
      </v-list-item-title>
      <p class="ma-0"><span class="orange--text">Старое значение:</span> {{ item.old_value }}</p>
      <p><span class="green--text">Новое значение:</span> {{ item.new_value }}</p>
      <v-divider
        class="divider-items"
        v-if="index !== currentOpenItem.logItems.length-1"
      ></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailChanges',
  props: {
    currentOpenItem: Object
  }
}
</script>

<style scoped>
  .wrapper-info-user {
    padding: 15px;
    border: 1px solid #00b0ff;
    border-radius: 5px;
    margin-top: 15px;
    width: max-content;
    width: -moz-max-content;
  }
  .divider-items {
    border: dashed rgba(0, 159, 217, 0.32) 1.5px;
  }
</style>