<template>
  <div class="content-block">
    <BaseBtn
      title="Фильтр"
      color="primary"
      small
      class="mb-3 ml-2"
      v-if="false"
    />
    <BasePreloader v-if="isLoading"/>
    <BaseTable
      v-if="items.models && items.models.length"
      :items="items"
      :headers="headers"
      v-model="selectedItems"
      item-key="id"
      :options.sync="localQuery.options"
      :loading="isLoading"
      class="log-table"
    >
      <template #item="{item, isSelected}">
        <ListItem
          :item="item"
          @click="handleChangesBtn"
        />
      </template>
    </BaseTable>

    <BaseAlert
      v-if="!items.models || items.models.length === 0 && !isLoading"
      :text="$t('message.noChanges')"
      type="info"
      class="ml-2"
    />

    <BaseDialog
      hideScroll
      :maxWidth="600"
      v-model="isDialogChanges"
      is-cancel-btn
      text-cancel="Закрыть"
      :withLabel="false"
    >
      <template #content>
        <DetailChanges :current-open-item="currentOpenItem"/>
      </template>
    </BaseDialog>

  </div>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import BaseTable from '@/components/base/BaseTable'
import formatters from '@/components/mixins/formatters'
import ListItem from './ListItem'
import DetailChanges from './DetailChanges'
import BaseDialog from '@/components/base/BaseDialog'
import BasePreloader from '@/components/base/UI/BasePreloader'
import BaseAlert from '@/components/base/UI/BaseAlert'
import historyLog from '@/components/mixins/commonForLayouts/historyLog'

export default {
  name: 'HistoryLog',
  components: {
    ListItem,
    BaseTable,
    BaseBtn,
    DetailChanges,
    BaseDialog,
    BasePreloader,
    BaseAlert
  },
  mixins: [formatters, historyLog],
  props: {
    modelId: Number
  },
  data () {
    return {
      headers: [
        { text: 'Пользователь', value: 'user', sortable: false },
        { text: 'Организация', value: 'organization', sortable: false },
        { text: 'Статус', value: 'action' },
        { text: 'Дата', value: 'created_at' },
        { text: '', value: 'actions' }
      ],
      selectedItems: []
    }
  }
}
</script>

<style scoped>
  .content-block {
    height: calc(100vh - 75px);
    overflow: hidden;
    overflow-y: visible;
  }
  .log-table /deep/ .v-data-table__wrapper { /* высота контента таблицы*/
    max-height: calc(100vh - 198px) !important;
  }
</style>